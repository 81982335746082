.icon {
  position: fixed;
  bottom: 64px;
  right: 42px;
  cursor: pointer;
  box-shadow: 0 0 10px 7px white;
  border-radius: 50%;
  z-index: 1000;
}

.icon:hover {
  opacity: 0.7;
}

.section {
  position: fixed;
  bottom: 20%;
  right: 140px;
  padding: 24px;
  background-color: var(--color-light);
  border-radius: 18px;
  box-shadow: 10px 8px 16px;
  z-index: 999;
  font-style: normal;
  height: 600px;
  width: 470px;
  display: flex;
  flex-direction: column;
}

.section button {
  background-color: transparent;
  border: none;
}

.section img {
  height: 42px;
  width: 42px;
  background-color: var(--color-gray-min);
  border-radius: 50%;
  margin-right: 18px;
}

.start {
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--color-gray);
}

.start h4 {
  font-style: normal;
  text-transform: none;
  font-weight: 600;
  margin-left: 22px;
}

.start button {
  padding: 4px 6px;
  border: none;
  color: var(--color-gray-bold);
}

.start button:hover {
  background-color: var(--color-gray-bold);
  color: var(--color-gray-min);
}

.center {
  margin: 16px 0;

  overflow-y: auto;
  height: 430px;
}

.center > div p {
  margin-bottom: 12px;
  padding: 6px 16px;
  display: inline-block;
  border-radius: 7px;
  max-width: 85%;
}

.center div {
  display: flex;
}

.center .to {
  justify-content: flex-end;
}

.center .to > p {
  background-color: cornflowerblue;
  color: white;
  align-items: end;
}

.center .from > p {
  background-color: var(--color-gray-min);
  color: var(--color-gray-bold);
}

.endChat {
  position: absolute;
  bottom: 53px;
  font-size: 0.75rem;
  right: 110px;
}

.end {
  position: absolute;
  bottom: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.end > input {
  font-style: normal;
  width: 216px;
}

.end > i {
  cursor: pointer;
}

/* animation */

.section {
  animation-name: popup;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

@keyframes popup {
  0% {
    transform: scale(0) rotate(-12deg);
    opacity: 0.25;
  }
  45% {
    transform: scale(1) rotate(6deg);
    opacity: 0.75;
  }
  90% {
    transform: scale(1) rotate(-2deg);
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
}
