.product {
  display: flex;
  gap: 48px;
}

.imgs {
  display: grid;
  grid-template-columns: 1fr 4.5fr;
  grid-template-rows: repeat(4 1fr);
  gap: 16px;
  flex-basis: 50%;

  align-items: center;
}

.imgs > img {
  cursor: pointer;
}

.imgs > img:last-child {
  grid-column: 2;
  grid-row: 1/5;
  cursor: unset;
  /* max-width: 100%; */
  max-height: 700px;
}

.detail {
  flex-basis: 50%;
}

.detail h1 {
  font-weight: 600;
}

.detail :first-child > p {
  color: var(--color-gray-bold);
  margin: 24px 0;
  font-size: 1.5rem;
}

.category {
  margin-top: 24px;
  display: flex;
  gap: 12px;
}

.navigate > p {
  margin-top: 36px;
  display: inline-block;
  padding: 7px 24px;
  padding-right: 36px;
  font-size: 1.1rem;
  border: 0.5px solid var(--color-gray);
  border-right: none;
}

.navigate > button {
  margin-top: 36px;
  padding: 7px 32px;
  border: none;
  background-color: var(--color-primary);
  color: var(--color-gray);
}

.navigate > button:hover {
  background-color: var(--color-gray);
  color: var(--color-primary);
}
