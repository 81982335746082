.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.777);
}

.overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 42px;
  transform: translate(-50%, -50%);
  z-index: 100;
  background-color: var(--color-light);
  height: 70vh;
  width: 80vw;
  display: flex;
  gap: 42px;
  align-items: center;
}

.overlay > div {
  flex-basis: 50%;
  height: 100%;
}

.overlay img {
  height: 100%;
  object-fit: cover;
}

.detail {
  position: relative;
}

.detail > div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.detail > div > div {
  margin: 0 42px 0 17px;
}

.detail h1 {
  font-weight: 600;
}

.detail > div > div > p {
  color: var(--color-gray-bold);
  margin-top: 12px;
  font-size: 1.5rem;
}

.detail i {
  color: var(--color-light);
}

.detail button {
  margin-top: 36px;
  padding: 7px 32px;
  border: none;
  background-color: var(--color-primary);
  color: var(--color-gray);
}

.detail button:hover {
  background-color: var(--color-gray);
  color: var(--color-primary);
}

.x {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 1.7rem;
}

.x:hover {
  color: var(--color-primary);
}
