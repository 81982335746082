.section > h2 {
  margin-bottom: 2.5rem;
}

.cart {
  text-align: center;
}

.cart li {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  justify-items: center;
  gap: 1rem;
}

.title {
  display: flex;
  gap: 1rem;
  justify-content: space-evenly;
}

.title > :nth-child(2) {
  width: 21rem;
}

.cart li > :nth-child(2) {
  grid-column: 2/4;
}

.title,
.navigate {
  margin-bottom: 1rem;
  background-color: var(--color-gray-min);
}

.title > h4 {
  padding: 12px 24px;
}

.navigate {
  display: flex;
  justify-content: space-between;
  margin-top: 64px;
  padding: 24px;
}

.navigate > button {
  background-color: transparent;
  color: var(--color-gray-bold);
  padding: 8px 18px;
}

.navigate > button:hover {
  color: var(--color-primary);
}

.navigate > button:first-child {
  border: none;
}

.cart li {
  margin-bottom: 16px;
}

.cart li > h4 {
  text-transform: none;
}

.cart li > div > span {
  border: none;
}

.cart li > i:hover {
  cursor: pointer;
  color: var(--color-primary);
}

.section > div {
  display: flex;
  gap: 36px;
  align-items: flex-start;
}

.section > div > :first-child {
  flex: 2;
}

.section > div > :last-child {
  flex: 1;
}
