.formList > :first-child {
  padding-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: self-start;
}

.formList input {
  height: 48px;
  width: 394px;
  outline: none;
}

.formList input::placeholder {
  color: var(--color-gray);
}

.formList select {
  width: 255px;
}

.products {
  margin: 24px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.pageNum {
  text-align: end;
  font-size: 2rem;
}

.pageNum button {
  padding: 0.2rem 1.1rem;
  border: 0.5px solid #ced4da;
  background-color: var(--color-light);
  cursor: no-drop;
}

.pageNum span {
  color: #ced4da;
  background-color: var(--color-primary);
  padding: 0.5rem 1.4rem;
  margin: 0 7px;
}

.pageNum p {
  font-size: 18px;
}
