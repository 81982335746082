.section {
  text-transform: uppercase;
}

.section p {
  color: var(--color-gray);
}

.section h3 {
  margin: 4px 0 24px;
}

.section h4 {
  font-size: 1.3rem;
}

.products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
}
