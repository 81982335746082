.footer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 7px;
  padding: 62px 0;
}

.nav h3 {
  margin-bottom: 24px;
}

.nav a {
  color: var(--color-gray);
}
