.total {
  padding: 4rem 3.5rem;
  background-color: var(--color-gray-min);
}

.total > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sub {
  margin-top: 36px;
  padding-bottom: 16px;
  border-bottom: 0.5px solid var(--color-gray-bold);
}

.price {
  margin-top: 8px;
}

.price > p {
  font-size: 1.5rem;
  color: var(--color-gray-bold);
}

.total > input,
.total button {
  width: 100%;
  height: 3.5rem;
}

.total > input {
  margin-top: 1rem;
  padding: 0.7rem;
  outline: none;
}

.total > button {
  background-color: var(--color-primary);
  color: var(--color-gray);
  text-align: center;
  border: none;
}

.total > button:hover {
  background-color: var(--color-light);
}
