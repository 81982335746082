.banner {
  margin-top: 0;
  text-transform: uppercase;
  background-color: var(--color-gray-min);
  padding: 120px 62px;
  display: flex;
  justify-content: space-between;
}

.banner h1 {
  font-size: 3.5rem;
}

.banner p {
  color: var(--color-gray-bold);
  margin-right: 42px;
}

.banner > div {
  display: flex;
}

.banner > div a:not(:last-child)::after {
  margin: 10px;
  content: "/ ";
}

.banner > div > a {
  margin: 0;
  color: var(--color-primary);
}

.banner > div > a:last-child {
  color: var(--color-gray-bold);
}
